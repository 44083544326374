import * as React from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";
import { StaticImage } from "gatsby-plugin-image"
import "../css/about.css";

export default function About() {
    return (
        <>
            <Seo title="About" />
            <BrandHeading title="about us" />
            <section>
                <div className="font-gotu text-justify text-[0.78rem] md:text-[0.85rem] tracking-wider md:tracking-[0.06em] text-pas-gray max-w-3xl mx-auto">

                    <div id="cfaboutus" className="flex items-center">
                        <div className="banner-image z-30">
                            <StaticImage
                                src="../images/aboutus/pasteleria_joel2.jpg"
                                quality={100}
                                placeholder="blurred"
                                formats={["auto", "webp", "avif"]}
                                alt="Pasteleria Cake" />
                        </div>
                        <div className="banner-image z-20">
                            <StaticImage
                                src="../images/aboutus/pasteleria_joel4.jpg"
                                quality={100}
                                placeholder="blurred"
                                formats={["auto", "webp", "avif"]}
                                alt="Pasteleria Cake" />
                        </div>
                        <div className="banner-image z-10">
                            <StaticImage
                                src="../images/aboutus/pasteleria_joel3.jpg"
                                quality={100}
                                placeholder="blurred"
                                formats={["auto", "webp", "avif"]}
                                alt="Pasteleria Cake" />
                        </div>
                        <div className="z-0 opacity-0">
                            <StaticImage
                                src="../images/aboutus/pasteleria_joel3.jpg"
                                quality={100}
                                placeholder="blurred"
                                formats={["auto", "webp", "avif"]}
                                alt="Pasteleria Cake" />
                        </div>
                    </div>


                    <p className="font-ovo text-left md:text-left text-lg md:text-2xl tracking-wider leading-7 md:leading-8 mb-2 md:mb-3 mt-12 md:mt-[4.5rem]">Hi! I'm Thea, and along with my mum, Marlene,</p>
                    <p className="font-ovo text-right md:text-right text-lg md:text-2xl tracking-wider leading-7 md:leading-8 pb-7 md:pb-[3.25rem]">we run PASTELERÍA (pahs-teh-leh-ree-yah)</p>
                    <p className="leading-[1.6rem] md:leading-8 pb-[1.8rem] md:pb-8">Mum and I started baking for family and friends, and as more people complimented our cakes, we realised we were onto something special. That gave us the confidence to open our home-based bakery.</p>
                    <p className="leading-[1.6rem] md:leading-8 pb-[1.8rem] md:pb-8">I never imagined becoming a pastry chef. After studying Spatial Design and working as an interior designer, I found my true passion in baking and pursued a Pâtisserie course. Mum, whose love for baking started in grade school, always dreamed of having her own bakeshop. Now, that dream has become a reality!</p>
                    <p className="leading-[1.6rem] md:leading-8 pb-[1.8rem] md:pb-8">At Pastelería, we don’t cut corners. We use only high-quality ingredients and make everything from scratch, creating cakes that are as delicious as they are beautiful. We also offer gluten-free, dairy-free, and vegan options for those with dietary intolerances.</p>
                    <p className="leading-[1.6rem] md:leading-8 pb-[1.8rem] md:pb-8">So welcome! We’re excited to share our creations and look forward to baking for you!</p>

                </div>
            </section>

        </>
    )
}